<template>
  <div
    id="users"
    class="page-container-table"
  >
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm người dùng'"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickDowloadSample="downloadExampleFileUsers"
      @clickExportExcel="downloadSampleUsers"
      @importFile="importFileExcel($event)"
      @clickAdd="$router.push({name: 'create-user'})"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataListUsers || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Column -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span
          v-if="props.column.label ==='Code'"
          class="text-nowrap"
        >
          MÃ NGƯỜI DÙNG
        </span>
        <span
          v-else-if="props.column.label ==='FullName'"
          class="text-nowrap"
        >
          TÊN NGƯỜI DÙNG
        </span>
        <span
          v-else-if="props.column.label ==='userTypeName'"
          class="text-nowrap"
        >
          KIỂU NGƯỜI DÙNG
        </span>
        <span
          v-else-if="props.column.label ==='Constructor'"
          class="text-nowrap"
        >
          CƠ CẤU TỔ CHỨC
        </span>
        <span
          v-else-if="props.column.label ==='registeredDate'"
          class="text-nowrap"
        >
          NGÀY THAM GIA
        </span>
        <span
          v-else-if="props.column.label ==='Status'"
          class="text-nowrap"
        >
          TRẠNG THÁI
        </span>
        <span
          v-else-if="props.column.label ==='ActionFunction'"
          class="text-nowrap"
        >
          CHỨC NĂNG
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Mã người dùng -->
        <span
          v-if="props.column.field === 'code'"
          class="text-nowrap"
        >
          <b-badge
            pill
            class="light-code"
          >
            <span class="text-name">{{ $t(props.row.code) }}</span>
          </b-badge>
        </span>

        <!-- Column:Tên người dùng -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-media>
            <template #aside>
              <user-avatar :data="props.row" />
            </template>
            <span class="font-weight-bold text-name">{{ props.row.name }}</span>
            <div>
              <span>{{ $t(props.row.userName) }}</span>
            </div>
          </b-media>
        </span>

        <!-- Column:nKiểu người dùng-->
        <span v-else-if="props.column.field === 'userTypeName'">
          <span class="text-nowrap">{{ props.row.userType }}</span>
        </span>

        <!-- Column:Cơ cấu tổ chức-->
        <span v-else-if="props.column.field === 'constructor'">
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column:ngày tạo -->
        <span v-else-if="props.column.field === 'registeredDate'">
          <span class="text-nowrap">{{ props.row.created | formatDateToDDMM }}</span>
        </span>

        <!-- Column:Trạng thái -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge
            pill
            :variant="statusVariant(props.row.emailConfirmed)"
            class="border-status"
          >
            <span>{{ props.row.emailConfirmed === true ? 'Đã kích hoạt' : 'Chưa kích hoạt' }}</span>

          </b-badge>
        </span>
        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'actionFunction'">
          <span @click="$router.push({name: 'edit-user', params: {id: props.row.id}})">
            <feather-icon
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
          <span>
            <b-dropdown
              variant="link"
              boundary="viewport"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body"
                />
              </template>
              <!-- xem chi tiet  -->
              <b-dropdown-item
                @click="handleActive(props.row.userId, props.row.emailConfirmed)"
              >
                <feather-icon
                  v-if="!props.row.emailConfirmed"
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <feather-icon
                  v-if="props.row.emailConfirmed"
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>{{ props.row.emailConfirmed === false ? 'Kích hoạt' : 'Hủy kích hoạt' }}</span>
                <!-- <span>{{ $t("common.action-table.see-detail") }}</span> -->
              </b-dropdown-item>
              <!-- xem bao cao  -->
              <b-dropdown-item @click="handleOpenModal(props.row.userId)">
                <feather-icon
                  icon="LockIcon"
                  class="mr-50"
                />
                <span>Cấp mật khẩu</span>
                <!-- <span>{{ $t("common.action-table.see-report") }}</span> -->
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.currentPage"
      @pageClick="handlePageClick"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- modal cấp lại mật khẩu -->
    <modal-reissue-password
      idModalReissuePassword="modalPasswoord"
      @handleReissuePassword="handleReissuePassword"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalReissuePassword from './components/ModalReissuePassword.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    ModalReissuePassword,
    BButton,
    VBTooltip,
    ButtonAllHeader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'Code',
          field: 'code',
          sortable: false,
        },
        {
          label: 'FullName',
          field: 'fullName',
          sortable: false,
        },
        {
          label: 'userTypeName',
          field: 'userTypeName',
          sortable: false,
        },
        {
          label: 'Constructor',
          field: 'constructor',
          sortable: false,
        },
        {
          label: 'registeredDate',
          field: 'registeredDate',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'ActionFunction',
          field: 'actionFunction',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      userId: '',
      arrayExcel: [
        'code',
        'userName',
        'password',
        'name',
        'email',
        'birthDate',
        'gender',
        'address',
        'phoneNumber',
        'userType',
        'groupUser',
        'position',
        'orStruc'],
      dataListUsers: [],
      totalRecord: 0,
    }
  },
  computed: {
    // ...mapGetters('users', ['dataListUsers', 'totalRecord']),
    statusVariant() {
      const statusColor = {
        // Chưa kích hoạt
        false: 'light-danger',
        // Kích hoạt
        true: 'light-success',
      }
      return status => statusColor[status]
    },
  },
  created() {
    this.fetchListUsers(this.urlQuery)
  },
  methods: {
    ...mapActions('users', [
      'downloadSampleUsers',
      'getApiExcelUser',
      'downloadExampleFileUsers',
    ]),

    // Fetch data
    async fetchListUsers(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_USERS, {
        params: urlQuery,
      })
      this.dataListUsers = data.data.pageLists
      this.totalRecord = data.data.totalRecord
    },

    async handleActive(id, check) {
      if (check === false) {
        await axiosApiInstance.post(`${ConstantsApi.ACTIVE_ACCOUNT}${id}`).then(res => {
          if (res.status === 200) {
            this.$bvToast.toast('Kích hoạt thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.fetchListUsers(this.urlQuery)
          }
        }).catch(e => {
          this.$bvToast.toast('Kích hoạt không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      } else {
        await axiosApiInstance.post(`${ConstantsApi.UN_ACTIVE_ACCOUNT}${id}`).then(res => {
          if (res.status === 200) {
            this.$bvToast.toast('Hủy kích hoạt thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.isActive = false
            this.fetchListUsers(this.urlQuery)
          }
        }).catch(e => {
          this.$bvToast.toast('Hủy kích hoạt không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchListUsers(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchListUsers(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchListUsers(this.urlQuery)
      }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = this.$t('Bạn có muốn xóa không?')
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = this.$t('Bạn có muốn xóa không?')
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      await axiosApiInstance.post(ConstantsApi.DELETE_USERS, this.deleteIds).then(() => {
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListUsers(this.urlQuery)
      }).catch(e => {
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Mở modal
    handleOpenModal(id) {
      this.userId = id
      this.$bvModal.show('modalPasswoord')
    },

    // Cấp lại mật khẩu cho người dùng
    async handleReissuePassword(val, validate) {
      const payload = {
        ...val,
        userId: this.userId,
      }
      validate.validate().then(async success => {
        if (success) {
          await axiosApiInstance.post(ConstantsApi.CHANGE_PASSWORD, payload).then(res => {
            if (res.status === 200) {
              this.$bvToast.toast('Cập nhật thành công', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
              val.confirmNewPassword = ''
              val.newPassword = ''
              this.$bvModal.hide('modalPasswoord')
            } else {
              this.$bvToast.toast(`${res.data?.errors.ConfirmNewPassword[0]}`, {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            }
          })
        }
      })
    },

    // import file exel
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    // kiểm tra hợp lệ từ file exel
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcelUser(model)
      await this.$router.push({ name: 'import-user' })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
